import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from './http';

export const uploadFile = async (file, fileType, applicationId) => {
  try {
    const fileResponse = await backend.post(
      `/reference-verification/file-upload/${fileType}/${applicationId}`,
      {
        file
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return fileResponse;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const deleteFile = async (fileType, applicationId, filePath) => {
  try {
    const fileResponse = await backend.post(
      `/reference-verification/file-delete/${fileType}/${applicationId}`,
      {
        filePath
      }
    );
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const downloadFile = async (fileType, applicationId, filePath) => {
  try {
    const fileResponse = await backend.post(
      `/reference-verification/file-download/${fileType}/${applicationId}`,
      {
        filePath
      }
    );
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const finalSubmitForm = async (applicationId) => {
  try {
    const fileResponse = await backend.post(
      `/reference-verification/final-submit`,
      {
        applicationId
      },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return fileResponse;
  } catch (error) {
    renderHttpErrors(error, false);
    return false;
  }
};

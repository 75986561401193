import {
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
  useRoutes
} from 'react-router-dom';
import { CssBaseline, Typography } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrivateRoute from './routing/PrivateRoute';
import { useEffect } from 'react';
import { backend } from './services/http';
import store from './redux/store';
import { loadUser } from './redux/actions/auth';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AccessPage from './pages/access';
import ViewApplication from './pages/view';
import SidebarLayout from './layouts/SidebarLayout';
import ThankYouPage from './pages/thankyou';
import FileNotFoundPage from './pages/404';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route
            path="/access/:applicationId/:referenceId"
            element={
              <SidebarLayout>
                <AccessPage />
              </SidebarLayout>
            }
          />
          <Route
            path="/view/:applicationId/:referenceId"
            element={
              <SidebarLayout>
                <ViewApplication />
              </SidebarLayout>
            }
          />
          <Route
            path="/404"
            element={
              <SidebarLayout>
                <FileNotFoundPage />
              </SidebarLayout>
            }
          />
          <Route
            path="/thankyou"
            element={
              <SidebarLayout>
                <ThankYouPage />
              </SidebarLayout>
            }
          />
          <Route
            path="*"
            element={
              <SidebarLayout>
                <FileNotFoundPage />
              </SidebarLayout>
            }
          />
        </Routes>
        <ToastContainer theme={'colored'} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Grid,
  Container,
  CardContent,
  Card,
  TextField,
  Box,
  Typography,
  Button,
  Avatar,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import Footer from 'src/components/Footer';
import { useNavigate, useParams } from 'react-router';
import { applications_mock } from 'src/__mock__/data';
import { useEffect, useState } from 'react';
import { IApplication } from 'src/interfaces/Application';
import { getSingleApplication } from 'src/redux/actions/applications';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import Loading from 'src/components/Loading';
import Header from 'src/layouts/SidebarLayout/Header';
import { Link } from 'react-router-dom';
import { LockOutlined } from '@mui/icons-material';
import { backend } from 'src/services/http';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { Form, Formik } from 'formik';

type Props = {
  getSingleApplication;
  currentApplication;
  loading;
};

const submitAuthCode = async (authCode, applicationId, referenceId) => {
  try {
    const response = await backend.post(
      '/reference-verification/verify-auth-code',
      {
        authCode,
        applicationId,
        referenceId
      }
    );
    // toast('Submission successfull', { type: 'success' });
    if (response?.data?.data?.token) {
      return response?.data?.data?.token;
    } else {
      return false;
    }
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};
export const checkLinkValidity = async (applicationId, referenceId) => {
  try {
    const response = await backend.get(
      `/reference-verification/valid-link/${applicationId}/${referenceId}`
    );
    const valid = response?.data?.data?.valid;
    return valid === true ? true : false;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

function AccessPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { applicationId, referenceId } = params;

  useEffect(() => {
    async function init() {
      const valid = await checkLinkValidity(applicationId, referenceId);
      if (!valid) {
        navigate('/404');
      }
    }

    init();
  }, []);

  const handleAuthCodeSubmit = async ({ authCode }) => {
    const token = await submitAuthCode(authCode, applicationId, referenceId);
    if (token) {
      localStorage.setItem('reference-verification-auth-token', token);
      navigate(`/view/${applicationId}/${referenceId}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Pulse Care - Verification</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg" sx={{ flex: 1, mb: 12 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item lg={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Access Window
              </Typography>
              <Formik
                initialValues={{ authCode: '' }}
                onSubmit={(values) => handleAuthCodeSubmit(values)}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form noValidate autoComplete="off">
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="authCode"
                        placeholder="6 Digit Access Code"
                        name="authCode"
                        autoComplete="authCode"
                        autoFocus
                        inputProps={{
                          style: { textAlign: 'center', fontSize: 16 },
                          maxLength: 6
                        }}
                        onChange={handleChange}
                        value={values.authCode}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default AccessPage;

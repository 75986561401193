import { Typography, Grid, Box } from '@mui/material';
import { getApplicantFullName } from '.';

type Props = {
  data;
};

function PageHeader({ data }: Props) {
  const applicantName = getApplicantFullName(data) || '';
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h3" component="h3" gutterBottom>
            Do you know {applicantName}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {/* {stage} */}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          Hey, {data?.refereeDetails?.fullName}. Please help us verify{' '}
          {applicantName}'s profile. You have been mentioned as a reference
          while {data?.personalInformation?.firstName} worked at QSS Inc. As
          part of our standard reference check process, we would like to ensure
          the accuracy of the information they have provided. Your input in this
          matter is highly valued, and your perspective will help us make an
          informed decision.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;

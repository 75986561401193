import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';

type Props = {
  label?: string;
  handleChange;
  value;
};

const DateSelector = (props: Props) => {
  return (
    <DatePicker
      sx={{ width: '100%' }}
      slotProps={{ textField: { size: 'small' } }}
      label={props.label}
      onChange={props.handleChange}
      value={props.value}
    />
  );
};

export default DateSelector;

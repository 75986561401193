import { CheckCircle, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  Link,
  TextField,
  Typography
} from '@mui/material';
import React, { ChangeEventHandler, useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadFile } from 'src/services';

type Props = {
  handleFileChange?: any;
  handleFileDelete?: any;
  viewOnly?: boolean;
  link?: string;
  loading?: boolean;
};

const FileUpload = ({
  handleFileChange,
  handleFileDelete,
  viewOnly,
  link,
  loading
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return loading ? (
    <Box
      width="100%"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      border={1}
      borderRadius={1}
      borderColor={'#ccc'}
      p={1}
      height={40}
    >
      <CircularProgress color="primary" size={24} />
    </Box>
  ) : !viewOnly ? (
    <TextField
      type={'file'}
      // inputProps={{ accept: 'application/pdf' }}
      size="small"
      onChange={(e: any) => {
        handleFileChange(e.target.files[0]);
      }}
    />
  ) : (
    <Box
      width="100%"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      border={1}
      borderRadius={1}
      borderColor={'#ccc'}
      p={1}
      height={40}
    >
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <CheckCircle color="success" />
        <Link
          title="Download file"
          sx={{ ':hover': { cursor: 'pointer' } }}
          onClick={() => downloadFile('pulse', applicationId, link)}
        >
          File Uploaded
        </Link>
      </Box>
      <IconButton onClick={handleFileDelete} size="small" color="error">
        <DeleteOutline />
      </IconButton>
    </Box>
  );
};

export default FileUpload;

import { Grid, Typography } from '@mui/material';
import { Container, Box } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';

type Props = {};

const ThankYouPage = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>Pulse Care - Verification</title>
      </Helmet>
      {/* <PageTitleWrapper>
    <PageHeader />
  </PageTitleWrapper> */}
      <Container maxWidth="lg" sx={{ flex: 1, mb: 12 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item lg={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
              mt={32}
            >
              <Typography component="h1" variant="h5">
                Your feedback has been recorded. Thank you. 🙏. You can go ahead
                and close this browser tab.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ThankYouPage;

import { Typography, Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

function PageHeader() {
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Grid item></Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Hello there.
        </Typography>
        <Typography variant="subtitle2">
          One of your connections has applied for a position with our
          organization, and as part of our standard reference check process, we
          would like to ensure the accuracy of the information they have
          provided. Your input in this matter is highly valued, and your
          perspective will help us make an informed decision. If you think you
          have access to this link by mistake, please ignore.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;

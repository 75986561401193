import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import FileUpload from 'src/components/FileUpload';
import Footer from 'src/components/Footer';
import Loading from 'src/components/Loading';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import PageHeader from './PageHeader';
import React from 'react';
import { uploadFile, deleteFile } from 'src/services';
import { checkLinkValidity } from '../access';
import DateSelector from 'src/components/DateSelector';
import { format } from 'date-fns';

const finalSubmit = async (values, applicationId, referenceId) => {
  try {
    const response = await backend.post(`/reference-verification/feedback`, {
      ...values,
      applicationId,
      referenceId
    });

    return response?.data?.data;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};
const getApplicationDetails = async (applicationId, referenceId) => {
  try {
    const token = localStorage.getItem('reference-verification-auth-token');
    if (!token) {
      toast('Invalid authentication token', { type: 'error' });
      return false;
    }
    backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await backend.get(
      `/reference-verification/application-details/${applicationId}/${referenceId}`
    );

    return response?.data?.data;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const getApplicantFullName = (application) => {
  return `${application?.personalInformation?.firstName} ${application?.personalInformation?.middleName} ${application?.personalInformation?.lastName}`;
};

function ViewApplication() {
  const params: any = useParams();
  const navigate = useNavigate();
  const [formData, setFormData]: any = useState({});
  const [loading, setLoading] = useState(true);
  const { applicationId, referenceId } = params;

  const [fileUploadLoading, setFileUploadLoading] = useState({
    stamp: false
  });
  const [fileToDelete, setFileToDelete] = useState({
    fileType: '',
    key: ''
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [finalSubmitOpen, setFinalSubmitOpen] = React.useState(false);

  const getFormData = async () => {
    setLoading(true);
    const data = await getApplicationDetails(applicationId, referenceId);
    setLoading(false);
    if (!data) {
      navigate('/404');
    }
    setFormData(data);
  };
  useEffect(() => {
    async function init() {
      const valid = await checkLinkValidity(applicationId, referenceId);
      getFormData();
      if (!valid) {
        navigate('/404');
      }
    }

    init();
  }, []);

  const handleFileUpload = async (file, type: any) => {
    try {
      setFileUploadLoading({ ...fileUploadLoading, [type]: true });
      const data: any = await uploadFile(file, type, applicationId);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
      return data.data;
    } catch (error) {
      renderHttpErrors(error);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
    }
  };

  const handleFileDelete = async (setFieldValue) => {
    const { fileType, key } = fileToDelete;
    setFieldValue(fileType, undefined);
    if (await deleteFile(fileType, applicationId, key)) {
      toast('Document deleted', { type: 'success' });
      setDeleteDialogOpen(false);
    }
  };

  const handleFinalSubmit = () => {
    setFinalSubmitOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Verify Applicant</title>
      </Helmet>
      {loading ? (
        <Loading text="Loading" />
      ) : (
        <>
          <PageTitleWrapper>
            <Container maxWidth="md">
              <PageHeader data={formData} />
            </Container>
          </PageTitleWrapper>
          <Container maxWidth="md">
            {formData?.personalInformation?.firstName ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Card>
                    <Formik
                      initialValues={{
                        applicantName: getApplicantFullName(formData) || '',
                        positionBandAppliedFor:
                          formData?.referenceFeedback?.positionBandAppliedFor ||
                          '',
                        // SECTION 1
                        title: formData?.refereeDetails?.title || '',
                        name: formData?.refereeDetails?.fullName || '',
                        positionBand:
                          formData?.referenceFeedback?.positionBand || '',
                        nmcHcpcPin:
                          formData?.referenceFeedback?.nmcHcpcPin || '',
                        iSupervisedApplicant:
                          formData?.referenceFeedback?.iSupervisedApplicant ||
                          'No',
                        capacityYouKnewTheApplicant:
                          formData?.referenceFeedback
                            ?.capacityYouKnewTheApplicant || '',
                        jobTitle: formData?.referenceFeedback?.jobTitle || '',
                        employmentStartDate:
                          formData?.referenceFeedback?.employmentStartDate ||
                          '',
                        employmentEndDate:
                          formData?.referenceFeedback?.employmentEndDate || '',
                        reasonForApplicantLeaving:
                          formData?.referenceFeedback
                            ?.reasonForApplicantLeaving || '',
                        orStillInPost:
                          formData?.referenceFeedback?.orStillInPost || 'No',
                        anyInvestigations:
                          formData?.referenceFeedback?.anyInvestigations || '',
                        anyConvictions:
                          formData?.referenceFeedback?.anyConvictions || '',
                        anyProceedings:
                          formData?.referenceFeedback?.anyProceedings || '',
                        // performaformData?.referenceFeedback?.// || ""e
                        skillsAndKnowledgeForRole:
                          formData?.referenceFeedback
                            ?.skillsAndKnowledgeForRole || '',
                        workloadManagement:
                          formData?.referenceFeedback?.workloadManagement || '',
                        documentationAndRecordManagement:
                          formData?.referenceFeedback
                            ?.documentationAndRecordManagement || '',
                        communicationSkills:
                          formData?.referenceFeedback?.communicationSkills ||
                          '',
                        supervisorySkills:
                          formData?.referenceFeedback?.supervisorySkills || '',
                        detailsForAnyUnsatisfactoryPerformance:
                          formData?.referenceFeedback
                            ?.detailsForAnyUnsatisfactoryPerformance || '',
                        stamp: formData?.referenceFeedback?.stamp || undefined
                      }}
                      onSubmit={async (values) => {
                        handleFinalSubmit();
                      }}
                    >
                      {({ values, handleChange, setFieldValue }) => (
                        <Form noValidate autoComplete="off">
                          <CardContent>
                            <Typography>
                              Fields marked with * are mandatory
                            </Typography>
                            <Grid mt={2} container spacing={2}>
                              {/* HEADING */}
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="applicantName"
                                      name="applicantName"
                                      label="Applicant Name"
                                      fullWidth
                                      variant="outlined"
                                      value={values.applicantName}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="positionBandAppliedFor"
                                      name="positionBandAppliedFor"
                                      label="Position / Band applied for"
                                      fullWidth
                                      variant="outlined"
                                      value={values.positionBandAppliedFor}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            {/* SECTION 1 */}
                            <Grid container mt={2} spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      Refree details (Refree need to fill their
                                      personal details below)
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="title"
                                      name="title"
                                      label="Title"
                                      fullWidth
                                      variant="outlined"
                                      value={values.title}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="name"
                                      name="name"
                                      label="Name"
                                      fullWidth
                                      variant="outlined"
                                      value={values.name}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="positionBand"
                                      name="positionBand"
                                      label="Position / Band"
                                      fullWidth
                                      variant="outlined"
                                      value={values.positionBand}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="nmcHcpcPin"
                                      name="nmcHcpcPin"
                                      label="NMC / HCPC PIN Number"
                                      fullWidth
                                      variant="outlined"
                                      value={values.nmcHcpcPin}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            {/* SECTION 2 */}
                            <Grid mt={2} container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="iSupervisedApplicant"
                                      name="iSupervisedApplicant"
                                      label="I was/am in a supervisory role to the applicant"
                                      fullWidth
                                      variant="outlined"
                                      value={values.iSupervisedApplicant}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="No">No</MenuItem>
                                      <MenuItem value="Yes">Yes</MenuItem>
                                    </TextField>
                                  </Grid>
                                  {values.iSupervisedApplicant === 'No' && (
                                    <Grid item xs={12}>
                                      <TextField
                                        sx={{ fontSize: '0.6rem' }}
                                        size="small"
                                        required
                                        id="capacityYouKnewTheApplicant"
                                        name="capacityYouKnewTheApplicant"
                                        label="If no, in what capacity did/do you know the applicant"
                                        fullWidth
                                        variant="outlined"
                                        value={
                                          values.capacityYouKnewTheApplicant
                                        }
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="jobTitle"
                                      name="jobTitle"
                                      label="Applicants job title / speciality / band in your company"
                                      fullWidth
                                      variant="outlined"
                                      value={values.jobTitle}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <DateSelector
                                      handleChange={(date) =>
                                        setFieldValue(
                                          'employmentStartDate',
                                          date
                                        )
                                      }
                                      value={values.employmentStartDate}
                                      label="Employment Start Date"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <DateSelector
                                      handleChange={(date) =>
                                        setFieldValue('employmentEndDate', date)
                                      }
                                      value={values.employmentEndDate}
                                      label="Employment End Date"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="orStillInPost"
                                      name="orStillInPost"
                                      label="Is the applicant still in post?"
                                      fullWidth
                                      variant="outlined"
                                      value={values.orStillInPost}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="No">No</MenuItem>
                                      <MenuItem value="Yes">Yes</MenuItem>
                                    </TextField>
                                  </Grid>
                                  {values.orStillInPost === 'No' && (
                                    <Grid item xs={12}>
                                      <TextField
                                        multiline
                                        rows={3}
                                        sx={{ fontSize: '0.6rem' }}
                                        size="small"
                                        required
                                        id="reasonForApplicantLeaving"
                                        name="reasonForApplicantLeaving"
                                        label="Applicant's Reason for leaving"
                                        fullWidth
                                        variant="outlined"
                                        value={values.reasonForApplicantLeaving}
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>

                            {/* SECTION 3 */}
                            <Grid container mt={2} spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant="caption"
                                      color={'error'}
                                    >
                                      Please complete the following questions,
                                      supplying information that is relevant to
                                      the applicant's suitability to role and
                                      fitness to practice in the context of
                                      public safety.
                                    </Typography>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Typography>
                                      Any current investigations or outcome
                                      warnings on the applicant's record
                                      relating to conduct, competence or
                                      performance?
                                    </Typography>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="anyInvestigations"
                                      name="anyInvestigations"
                                      fullWidth
                                      variant="outlined"
                                      value={values.anyInvestigations}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="No">No</MenuItem>
                                      <MenuItem value="Yes">Yes</MenuItem>
                                    </TextField>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Typography>
                                      Any criminal convictions/cautions/warnings
                                      held on the current criminal record of the
                                      applicant?
                                    </Typography>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="anyConvictions"
                                      name="anyConvictions"
                                      fullWidth
                                      variant="outlined"
                                      value={values.anyConvictions}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="No">No</MenuItem>
                                      <MenuItem value="Yes">Yes</MenuItem>
                                    </TextField>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Typography>
                                      Any fitness to practice proceedings,
                                      investigations and/ outcomes by the
                                      relevant regulatory body in the United
                                      Kingdom or any other country?
                                    </Typography>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="anyProceedings"
                                      name="anyProceedings"
                                      fullWidth
                                      variant="outlined"
                                      value={values.anyProceedings}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="No">No</MenuItem>
                                      <MenuItem value="Yes">Yes</MenuItem>
                                    </TextField>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant="caption"
                                      color={'error'}
                                    >
                                      If you answered yes to any of the
                                      questions above, we will confirm further
                                      information with the applicant or with
                                      yourself as necessary to access
                                      suitability for the role applied for. All
                                      information will be handled in accordance
                                      with the Equality Act (2010) and/or
                                      Rehabilitation of Offenders Act 1974
                                      (Exceptions) Order 1975 (as amended in
                                      2013) as applicable
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            {/* SECTION 4 */}
                            <Grid mt={2} container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      Please rate the applicant's performance
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="skillsAndKnowledgeForRole"
                                      name="skillsAndKnowledgeForRole"
                                      label="Skills & Knowledge for role"
                                      fullWidth
                                      variant="outlined"
                                      value={values.skillsAndKnowledgeForRole}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="Excellent">
                                        Excellent
                                      </MenuItem>
                                      <MenuItem value="Good">Good</MenuItem>
                                      <MenuItem value="Satisfactory">
                                        Satisfactory
                                      </MenuItem>
                                      <MenuItem value="Unsatisfactory">
                                        Unsatisfactory
                                      </MenuItem>
                                      <MenuItem value="Not Accessed / Not Applicable">
                                        Not Accessed / Not Applicable
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="workloadManagement"
                                      name="workloadManagement"
                                      label="Workload Management"
                                      fullWidth
                                      variant="outlined"
                                      value={values.workloadManagement}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="Excellent">
                                        Excellent
                                      </MenuItem>
                                      <MenuItem value="Good">Good</MenuItem>
                                      <MenuItem value="Satisfactory">
                                        Satisfactory
                                      </MenuItem>
                                      <MenuItem value="Unsatisfactory">
                                        Unsatisfactory
                                      </MenuItem>
                                      <MenuItem value="Not Accessed / Not Applicable">
                                        Not Accessed / Not Applicable
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="documentationAndRecordManagement"
                                      name="documentationAndRecordManagement"
                                      label="Documentation and Record Management"
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        values.documentationAndRecordManagement
                                      }
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="Excellent">
                                        Excellent
                                      </MenuItem>
                                      <MenuItem value="Good">Good</MenuItem>
                                      <MenuItem value="Satisfactory">
                                        Satisfactory
                                      </MenuItem>
                                      <MenuItem value="Unsatisfactory">
                                        Unsatisfactory
                                      </MenuItem>
                                      <MenuItem value="Not Accessed / Not Applicable">
                                        Not Accessed / Not Applicable
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="communicationSkills"
                                      name="communicationSkills"
                                      label="Communication Skills"
                                      fullWidth
                                      variant="outlined"
                                      value={values.communicationSkills}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="Excellent">
                                        Excellent
                                      </MenuItem>
                                      <MenuItem value="Good">Good</MenuItem>
                                      <MenuItem value="Satisfactory">
                                        Satisfactory
                                      </MenuItem>
                                      <MenuItem value="Unsatisfactory">
                                        Unsatisfactory
                                      </MenuItem>
                                      <MenuItem value="Not Accessed / Not Applicable">
                                        Not Accessed / Not Applicable
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="supervisorySkills"
                                      name="supervisorySkills"
                                      label="Supervisory Skills"
                                      fullWidth
                                      variant="outlined"
                                      value={values.supervisorySkills}
                                      onChange={handleChange}
                                      select
                                    >
                                      <MenuItem value="Excellent">
                                        Excellent
                                      </MenuItem>
                                      <MenuItem value="Good">Good</MenuItem>
                                      <MenuItem value="Satisfactory">
                                        Satisfactory
                                      </MenuItem>
                                      <MenuItem value="Unsatisfactory">
                                        Unsatisfactory
                                      </MenuItem>
                                      <MenuItem value="Not Accessed / Not Applicable">
                                        Not Accessed / Not Applicable
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="detailsForAnyUnsatisfactoryPerformance"
                                      name="detailsForAnyUnsatisfactoryPerformance"
                                      label="Details about any unsatisfactory performance (Optional)"
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        values.detailsForAnyUnsatisfactoryPerformance
                                      }
                                      onChange={handleChange}
                                      multiline
                                      minRows={3}
                                    />
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            {/* DESCRIPTION */}
                            <Grid mt={2} container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="caption"
                                      color={'error'}
                                    >
                                      References supplied are for the purposes
                                      of determining the applicant's suitability
                                      for work with our clients and may be
                                      shared with our clients for this purpose.
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="caption"
                                      color={'error'}
                                    >
                                      Data protection legislation allows
                                      applicants to request information held
                                      about them. Therefore, this information
                                      may be shared in accordance with
                                      legislative requirements.
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="caption"
                                      color={'error'}
                                    >
                                      The information provided in this reference
                                      has been given in good faith and is
                                      accurate to the best of my knowledge.
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            {/*  */}
                            <Grid mt={2} container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="signature"
                                      name="signature"
                                      label="Your Signature"
                                      fullWidth
                                      variant="outlined"
                                      value={formData?.refereeDetails?.fullName}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="date"
                                      name="date"
                                      label="Date"
                                      fullWidth
                                      variant="outlined"
                                      value={format(new Date(), 'dd/MM/yyyy')}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="referenceEmail"
                                      name="referenceEmail"
                                      label="Your Email"
                                      fullWidth
                                      variant="outlined"
                                      value={formData?.refereeDetails?.email}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="nameOfEmploymentOrganization"
                                      name="nameOfEmploymentOrganization"
                                      label="Name of Employment Organization"
                                      fullWidth
                                      variant="outlined"
                                      value={formData?.refereeDetails?.company}
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled
                                      sx={{ fontSize: '0.6rem' }}
                                      size="small"
                                      required
                                      id="referencePhone"
                                      name="referencePhone"
                                      label="Your Phone"
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        formData?.refereeDetails?.telephone
                                      }
                                      // onChange={handleChange}
                                    />
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container mt={2} spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  borderRadius={1}
                                  bgcolor={'#f8f8f8'}
                                  p={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                  width={'full'}
                                >
                                  <Typography>
                                    <span style={{ color: 'red' }}>
                                      IMPORTANT: <br />
                                    </span>
                                    For the reference to be valid one of the
                                    following forms of verification must be
                                    provided with the completed reference:
                                  </Typography>
                                  <List>
                                    <ListItem>
                                      <Typography>
                                        Hospital / Company Stamp
                                      </Typography>
                                    </ListItem>
                                    <ListItem>
                                      <Typography>
                                        Letter head /compliment slip attached
                                      </Typography>
                                    </ListItem>
                                    <ListItem>
                                      <Typography>
                                        E-mail confirmation from employer e-mail
                                        address
                                      </Typography>
                                    </ListItem>
                                    <ListItem>
                                      <Typography>
                                        For references provided from official
                                        company or hospital emails, attachments
                                        are not required.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <FileUpload
                                    loading={fileUploadLoading.stamp}
                                    viewOnly={values.stamp ? true : false}
                                    link={values.stamp ? values.stamp : ''}
                                    handleFileChange={async (file) => {
                                      const uploadedFile =
                                        await handleFileUpload(file, 'stamp');
                                      setFieldValue(
                                        'stamp',
                                        uploadedFile.data.file
                                      );
                                    }}
                                    handleFileDelete={() => {
                                      setFileToDelete({
                                        fileType: 'stamp',
                                        key: values.stamp
                                      });
                                      setDeleteDialogOpen(true);
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                            <Dialog
                              open={deleteDialogOpen}
                              onClose={() => setDeleteDialogOpen(false)}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                Confirm Deletion
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this document
                                  / attachment?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setDeleteDialogOpen(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="error"
                                  onClick={() =>
                                    handleFileDelete(setFieldValue)
                                  }
                                  autoFocus
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <Dialog
                              open={finalSubmitOpen}
                              onClose={() => setFinalSubmitOpen(false)}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                Confirm Submit
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to submit your feedback?
                                  Once submitted, this cannot be undone.
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setFinalSubmitOpen(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="error"
                                  onClick={async () => {
                                    console.log(values);
                                    const res: any = await finalSubmit(
                                      values,
                                      applicationId,
                                      referenceId
                                    );
                                    if (res) {
                                      toast('Feedback recorded. Thanks.', {
                                        type: 'success'
                                      });
                                      navigate('/thankyou');
                                    }
                                    setFinalSubmitOpen(false);
                                  }}
                                  autoFocus
                                >
                                  Yes, Submit
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </CardContent>
                          <CardActions>
                            <Box p={1} width={'100%'}>
                              <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                              >
                                Submit Feedback
                              </Button>
                            </Box>
                          </CardActions>
                        </Form>
                      )}
                    </Formik>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Loading text="Loading...." />
            )}
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}

export default ViewApplication;
